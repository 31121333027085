import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Layout from '../components/layouts'
import HeadMeta from '../components/HeadMeta'
import * as ds from '../uiLibrary'
import { GatsbyHeroImage } from '../components/heroes'
import {
  GatsbyImageSection,
  GatsbyQuoteSection,
  InfoContainerSection,
} from '../components'
import ChampionValues from '../components/ChampionValues'
import { Headline, H2 } from '../uiLibrary/Typographyv2'
import ImageSlider from '../components/ImageSlider'
import { LocationContext } from '../pages/index'
import { useLocale } from '../hooks/useLocale'
import { Banner } from '../components/Banner'

export const AboutPage = ({ data, ...props }) => {
  const page = data.allAboutJson.edges[0].node
  const bannerImage = data.banner.childImageSharp
  const screenshot = data.screenshots.edges[0]
  const firstImageSection = data.firstImageSection.edges[0].node.childImageSharp
  const quoteSectionData = data.quoteSectionImage.edges.map(({ node }, i) => {
    return { image: node.childImageSharp, ...page.quoteSection[i] }
  })
  const team = data.teamMembers.edges.map(({ node }, i) => {
    return { image: node.childImageSharp, ...page.team[i] }
  })

  const currentLocale = useLocale(props.location.pathname)

  // v2 banner doesn't have embed text in image
  const BannerImage = page.bannerHeadline ? Banner : GatsbyHeroImage

  return (
    <LocationContext.Provider value={props.location}>
      <Layout locale={currentLocale}>
        <HeadMeta
          pageTitle={page.pageTitle || 'Tonit'}
          pageDescription={page.pageDescription}
          socialTitle={page.socialTitle}
          socialDescription={page.socialDescription}
          socialImage={page.socialImage}
        />
        <ds.Background background={'#000000'}>
          <ds.FixedWidthContainer
            py={[ds.spacing.s5, ds.spacing.s6, ds.spacing.s6]}
          >
            <BannerImage
              url={bannerImage.fluid}
              headline={page.bannerHeadline}
              accent={ds.brandColors.mediumBlue}
            />
            <Headline
              textAlign={'center'}
              px={[ds.spacing.s3, ds.spacing.s4, ds.spacing.s5]}
              fontSize={[ds.typeScale.t6, ds.typeScale.t7, '4.998rem']}
              color={ds.brandColors.mediumBlue}
            >
              {page.heroHeading}
            </Headline>
            <H2
              color={ds.brandColors.typography.light}
              textAlign={['center', 'center']}
              px={[ds.spacing.s3, ds.spacing.s4, ds.spacing.s5]}
              fontSize={[ds.typeScale.t5, ds.typeScale.t7]}
            >
              {page.heroSubheading}
            </H2>
          </ds.FixedWidthContainer>
        </ds.Background>
        <ds.Background
          background={'#202020'}
          pb={[ds.spacing.s2, ds.spacing.s3, ds.spacing.s5]}
        >
          <InfoContainerSection
            {...page.introSection}
            pt={ds.spacing.s3}
            pb={ds.spacing.s3}
            triangle={true}
            index={1}
          />
          {page.imageSection1.map((m, i) => {
            return (
              <GatsbyImageSection
                key={firstImageSection.id}
                url={firstImageSection.fluid}
                {...m}
              />
            )
          })}
          {page.secondarySection.map((s, i) => {
            return (
              <InfoContainerSection
                key={`${i}${s.title}`}
                {...s}
                pt={ds.spacing.s3}
                pb={ds.spacing.s4}
                index={1}
              />
            )
          })}
          {quoteSectionData.map((q, i) => {
            if (i === 0) {
              return (
                <React.Fragment key={i}>
                  <GatsbyQuoteSection key={`${i}${q.smallText}`} {...q} />
                  <ds.FixedWidthContainer
                    bg={ds.brandColors.bg.white}
                    pt={ds.spacing.s7}
                    pb={ds.spacing.s7}
                  >
                    <ds.FixedWidthRow
                      display="flex"
                      py={0}
                      style={{ maxWidth: '100%', margin: '0' }}
                    >
                      <ds.Column span={12} textAlign="center">
                        <H2 color={ds.brandColors.typography.dark}>
                          {page.valuesTitle}
                        </H2>
                        <ChampionValues
                          values={page.values}
                          iconColor={page.valuesIconColor}
                        />
                      </ds.Column>
                    </ds.FixedWidthRow>
                  </ds.FixedWidthContainer>
                </React.Fragment>
              )
            } else {
              return (
                <React.Fragment key={`${i}`}>
                  <GatsbyQuoteSection
                    {...q}
                    key={`${i}${q.smallText}`}
                    url={q.image.fluid}
                  />
                  {
                    <ds.FixedWidthContainer bg={'white'}>
                      <ImageSlider items={team} />
                    </ds.FixedWidthContainer>
                  }
                </React.Fragment>
              )
            }
          })}
          <InfoContainerSection
            {...page.thirdSection}
            pt={[ds.spacing.s5, ds.spacing.s6]}
            pb={[ds.spacing.s5, ds.spacing.s7]}
            index={1}
          />
          <InfoContainerSection
            {...page.lastSection}
            image={screenshot.node.childImageSharp.fluid}
            pt={ds.spacing.s5}
            pb={ds.spacing.s6}
            index={1}
          />
        </ds.Background>
      </Layout>
    </LocationContext.Provider>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        banner: file(relativePath: { eq: "tonitaboutheader.png" }) {
          childImageSharp {
            fluid(maxWidth: 1440) {
              aspectRatio
              base64
              sizes
              src
              srcSet
            }
          }
        }
        quoteSectionImage: allFile(
          filter: { name: { regex: "/^aboutQuote*/" } }
          sort: { fields: name, order: ASC }
        ) {
          edges {
            node {
              childImageSharp {
                id
                fluid(maxWidth: 1200, quality: 100) {
                  aspectRatio
                  base64
                  sizes
                  src
                  srcSet
                }
              }
            }
          }
        }
        screenshots: allFile(
          filter: { name: { regex: "/^aboutScreenshot*/" } }
          sort: { fields: name, order: ASC }
        ) {
          edges {
            node {
              childImageSharp {
                id
                fluid(maxWidth: 400) {
                  aspectRatio
                  base64
                  sizes
                  src
                  srcSet
                }
              }
            }
          }
        }
        teamMembers: allFile(
          filter: { name: { regex: "/^teammember*/" } }
          sort: { fields: name, order: ASC }
        ) {
          edges {
            node {
              childImageSharp {
                id
                fixed(width: 300) {
                  ...GatsbyImageSharpFixed
                  height
                  width
                }
              }
            }
          }
        }
        firstImageSection: allFile(
          filter: { name: { regex: "/^featuremember*/" } }
          sort: { fields: name, order: ASC }
        ) {
          edges {
            node {
              childImageSharp {
                id
                fluid(maxWidth: 1200, quality: 100) {
                  aspectRatio
                  base64
                  sizes
                  src
                  srcSet
                }
              }
            }
          }
        }
        allAboutJson {
          edges {
            node {
              bannerHeadline
              heroHeading
              heroSubheading
              introSection {
                title
                titleColor
                header
                headerColor
                bodyColor
                body1
                body2
                body3
                button
                storeButton
                buttonLink
                buttonLabel
                buttonColor
                buttonFocus
                triangle
                bg
                textAlign
              }
              quoteSection {
                containerColor
                smallText
                smallTextColor
                largeText
                largeTextColor
                flip
                textAlign
                button
                storeButton
                buttonLink
                buttonLabel
                buttonBg
                buttonFocus
                backgroundAlt
              }
              secondarySection {
                title
                titleColor
                header
                headerColor
                bodyColor
                body1
                body2
                body3
                button
                storeButton
                buttonLink
                buttonLabel
                buttonColor
                buttonFocus
                triangle
                bg
                textAlign
              }
              thirdSection {
                title
                titleColor
                header
                headerColor
                bodyColor
                body1
                body2
                body3
                button
                storeButton
                buttonLink
                buttonLabel
                buttonColor
                buttonFocus
                triangle
                bg
                textAlign
              }
              lastSection {
                title
                titleColor
                header
                headerColor
                bodyColor
                body1
                body2
                body3
                button
                storeButton
                buttonLink
                buttonLabel
                buttonColor
                buttonFocus
                triangle
                bg
                order
                textAlign
                imageAlt
                imageExists
              }
              valuesTitle
              valuesIconColor
              values {
                icon
                title
                value
              }
              imageSection1 {
                containerColor
                urlDescription
                flip
                reverse
                button
                storeButton
                buttonLabel
                buttonColor
                buttonLink
                focus
                src
                box
                srcDescription
                titleColor
                title
                firstBody
                secondBody
                thirdBody
              }
              imageSection2 {
                containerColor
                urlDescription
                flip
                reverse
                button
                storeButton
                buttonLabel
                buttonColor
                buttonLink
                focus
                src
                box
                srcDescription
                titleColor
                title
                firstBody
                secondBody
                thirdBody
              }
              team {
                name
                position
                imageAlt
              }
              pageTitle
              socialTitle
              pageDescription
              socialDescription
              socialImage
            }
          }
        }
      }
    `}
    render={data => <AboutPage data={data} {...props} />}
  />
)
