import React from "react";
import Slider from "react-slick";
import styled from 'styled-components'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { CenteredPictureDisplay } from "./";

const UnstyledImageSlider = ({ items, className }) => {

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 6000,
    pauseOnFocus: false,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          autoplay: true,
        }
      }
    ]
  };

  const itemSections = items.map((t, i) =>
    <CenteredPictureDisplay
      key={i}
      {...t}
    />
  )

  return (
    <Slider className={className} {...settings} >
      {itemSections}
    </Slider>
  );
}

const ImageSlider = styled(UnstyledImageSlider)`
  @media(max-width: 960px) {
    .slick-track {
      display: flex;
      align-items: center;
    }
  }
`

export default ImageSlider
